import { newTracker } from "@snowplow/browser-tracker";

export const initSnowplow = () => {
  return new Promise((resolve) => {
    newTracker(
      "mytutor-web-tracker",
      `${process.env.NEXT_PUBLIC_SNOWPLOW_COLLECTOR_URL}`,
      {
        appId: process.env.NEXT_PUBLIC_SNOWPLOW_APP_ID,
        plugins: [],
      }
    );

    resolve();
  });
};
